import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { UserContextProvider, useUserContext } from "components/common/contexts";
import { apiDelete, apiGet, apiPost, apiPut } from "components/common/infractructure";
import { InvoiceSummary } from "components/common/ui-kit-business/InvoiceSummary/InvoiceSummary";
import { RouterErrorBoundary } from "components/common/ui-kit/components/ErrorBoundary";
import Loader from "components/Loader/Loader";
import Navigator from "components/Navigator/Navigator";
import NotAllowedRoute from "components/RBAC/NotAllowedRoute";
import {
    createDocumentOtherPayment,
    exportPendingPayment,
    getDocumentDetails,
    getDocuments,
    getEmployees,
    getExchangeRate,
    getPaymentSearchResult,
    getPendingPayment,
    getPendingPayments,
    moveInvoicesToBooked,
    sendBookInvoicesRequest,
    sendMoneyTransferRequest,
    unlockInvoiceEdition,
    updateDocumentOtherPayment,
    updateDocumentPayment,
    updatePendingPayment,
    uploadDocumentsWithSpecifications,
} from "modules/AccountingManagement/service";
import { AccountingPage } from "modules/AccountingManagement/views/AccountingPage";
import { DocumentView } from "modules/AccountingManagement/views/Documents/DocumentView";
import { AccountingView } from "modules/AccountingManagement/views/Payments/AccountingView";
import Bank from "modules/Bank/Bank";
import { BillingPage } from "modules/Billing/view/components/BillingPage";
import { InvoicesPage } from "modules/Invoices/InvoicesPage";
import { getInvoiceDetails, getOwnInvoices } from "modules/Invoices/service";
import { MonthlySettlementRoutes } from "modules/Invoices/views/MonthlySettlement/view";
import { ShowOwnInvoices } from "modules/Invoices/views/ShowOwnInvoices/ShowOwnInvoices";
import { ProjectsPage } from "modules/Projects/ProjectsPage";
import { getAllActiveUsers, getProjectConfig, getProjectsList } from "modules/Projects/service/apiService";
import { AddProject } from "modules/Projects/views/AddProject/AddProject";
import { ProjectsList } from "modules/Projects/views/ProjectsList/ProjectsList";
import { SalesSummaryView } from "modules/SalesSummary/SalesSummaryView";
import {
    addAuthCode,
    deleteGmailConsent,
    getGmailAuthUrl,
    getOrCreateUser,
    getUserProfileData,
    updateContractorData,
} from "modules/UserProfile/service/apiService";
import UserProfile from "modules/UserProfile/views/UserProfile";
import { getWelcomeData, updateProfile } from "modules/Welcome/service/apiService";
import { Welcome } from "modules/Welcome/views/Welcome";
import { useMemo } from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    useLocation,
    useNavigation,
} from "react-router-dom";
import RBAC from "components/RBAC/RBAC";
import { ROLES_PROJECTS_EDIT_CREATE, ROLES_PROJECTS_VIEW } from "components/RBAC/RBACutils";
import { DateString } from "components/common/types";
import Home from "modules/Home/Home";
import NotFoundRoute from "components/common/ui-kit/components/NotFound";
import { BankFileImport } from "modules/Bank/views/BankFileImport/BankFileImport";
import {
    assignDocumentPayment,
    getAccountsConfig,
    removeDocumentPayment,
    triggerSync,
} from "modules/Bank/service/apiService";
import Transactions from "modules/Bank/views/Transactions/Transactions";
import { getProjectSales } from "modules/SalesSummary/service";
import { ShowProjectRoute } from "../Projects/views/ShowProject";
import { subMonths } from "date-fns";
import { RegisterBadge } from "modules/Badge/views/RegisterBadge";
import { DocumentDetailsViewWrapper } from "../AccountingManagement/views/Documents/DocumentDetailsViewWrapper";
import { assignEmployeeToInvoicePosition, unassignEmployeeFromInvoicePosition } from "../Billing/service";
import { EmployeesList } from "../Employee/views/components/EmployeesList";
import {
    fireContractor,
    getEmployeeDetails,
    getPositionAssignments,
    getPromotionEmailPreview,
    getPromotions,
    notifyContractor,
    promoteContractor,
} from "../Employee/service";
import { EmployeesPage } from "../Employee/EmployeesPage";
import { EmployeeDetail } from "../Employee/views/components/EmployeDetail";
import { GmailCallback } from "modules/UserProfile/views/components/GmailCallback";

const Root = () => {
    const { user } = useAuth0();
    const navigation = useNavigation();
    const { userProfileData } = useUserContext();
    const { pathname } = useLocation();

    if (userProfileData.user.needsOnboarding && pathname !== "/welcome") {
        return <Navigate to="/welcome" />;
    }

    return (
        <Box
            sx={{
                height: "100vh",
                display: "grid",
                gridTemplateAreas: `
                    "nav header"
                    "nav main"
                `,
                gridTemplateColumns: "15rem auto",
                gridTemplateRows: "auto 1fr",
            }}
        >
            <Navigator user={user} />
            <Box
                sx={{
                    gridArea: "main",
                    padding: "2rem",
                    overflowY: "auto",
                    background: "url(/images/content-background.jpg) no-repeat center center",
                    backgroundSize: "cover",
                }}
            >
                {navigation.state === "loading" ? <Loader fullScreen /> : pathname === "/" ? <Home /> : <Outlet />}
            </Box>
        </Box>
    );
};

const MONTH_PARAM = "month";
const ID_PARAM = "id";

const AuthenticatedApp = () => {
    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route path="/" element={<Root />} errorElement={<RouterErrorBoundary />}>
                        <Route path="callback/gmail" element={<GmailCallback addAuthCode={addAuthCode(apiPost)} />} />
                        <Route path="billing/*" element={<BillingPage />} />
                        <Route path="badge/register" element={<RegisterBadge />} />
                        <Route path="employees" element={<EmployeesPage />}>
                            <Route
                                path=""
                                element={
                                    <EmployeesList
                                        notifyAboutPromotion={notifyContractor(apiPost)}
                                        getPromotions={getPromotions(apiGet)}
                                        getEmailPreview={getPromotionEmailPreview(apiGet)}
                                    />
                                }
                                loader={getPositionAssignments(apiGet)}
                            />
                            <Route
                                path={`/employees/:${ID_PARAM}`}
                                loader={({ params }) => getEmployeeDetails(apiGet)(params[ID_PARAM] as string)}
                                element={
                                    <EmployeeDetail
                                        fireContracotr={fireContractor(apiPost)}
                                        promoteContractor={promoteContractor(apiPost)}
                                    />
                                }
                            />
                        </Route>
                        <Route path="invoices" element={<InvoicesPage />}>
                            <Route path="" element={<ShowOwnInvoices />} loader={getOwnInvoices(apiGet)} />
                            <Route path="add/*" element={<MonthlySettlementRoutes />} />
                            <Route
                                path={`:${MONTH_PARAM}`}
                                loader={({ params }) => getInvoiceDetails(apiGet)(params[MONTH_PARAM] as DateString)}
                                element={<InvoiceSummary />}
                            />
                        </Route>
                        <Route path="bank" element={<Bank />}>
                            <Route path="transactions" element={<Transactions />} />
                            <Route
                                path="import"
                                element={<BankFileImport triggerSync={triggerSync(apiPost)} />}
                                loader={getAccountsConfig(apiGet)}
                            />
                        </Route>
                        <Route
                            path="profile"
                            element={
                                <UserProfile
                                    deleteGmailConsent={deleteGmailConsent(apiDelete)}
                                    getGmailAuthUrl={getGmailAuthUrl(apiGet)}
                                    updateContractorData={updateContractorData(apiPost)}
                                />
                            }
                        />
                        <Route path="sales" element={<SalesSummaryView getProjectSales={getProjectSales(apiGet)} />} />
                        <Route path="projects" element={<ProjectsPage />}>
                            <Route
                                path="list"
                                element={
                                    <RBAC requiredRoles={ROLES_PROJECTS_VIEW}>
                                        <ProjectsList getProjectsList={getProjectsList(apiGet)} />
                                    </RBAC>
                                }
                            />
                            <Route
                                path="create"
                                element={
                                    <RBAC requiredRoles={ROLES_PROJECTS_EDIT_CREATE}>
                                        <AddProject fetchActiveUsers={getAllActiveUsers(apiGet)} />
                                    </RBAC>
                                }
                                loader={getProjectConfig(apiGet)}
                            />
                            <Route
                                path={`show/:${ID_PARAM}`}
                                element={
                                    <RBAC requiredRoles={ROLES_PROJECTS_VIEW}>
                                        <ShowProjectRoute fetchActiveUsers={getAllActiveUsers(apiGet)} />
                                    </RBAC>
                                }
                                loader={ShowProjectRoute.Loader(ID_PARAM)}
                            />
                        </Route>
                        <Route path="accounting" element={<AccountingPage />}>
                            <Route
                                path="payments"
                                element={
                                    <AccountingView
                                        getPendingPayments={getPendingPayments(apiGet)}
                                        unlockInvoiceEdition={unlockInvoiceEdition(apiPost)}
                                        exportPendingPayment={exportPendingPayment(apiPost)}
                                        sendMoneyTransferRequest={sendMoneyTransferRequest(apiPost)}
                                        updatePendingPayment={updatePendingPayment(apiPost)}
                                        moveInvoicesToBooked={moveInvoicesToBooked(apiPost)}
                                        sendBookInvoicesRequest={sendBookInvoicesRequest(apiPost)}
                                    />
                                }
                            />
                            <Route
                                path={`invoice-preview/:${ID_PARAM}`}
                                loader={({ params }) => getPendingPayment(apiGet)(params[ID_PARAM] as string)}
                                element={<InvoiceSummary />}
                            />
                            <Route
                                path={`docs/show/:${ID_PARAM}`}
                                loader={({ params }) => getDocumentDetails(apiGet)(params[ID_PARAM] as string)}
                                element={
                                    <DocumentDetailsViewWrapper
                                        unassignEmployeeFromPosition={unassignEmployeeFromInvoicePosition(apiDelete)}
                                        assignEmployeeToPosition={assignEmployeeToInvoicePosition(apiPut)}
                                        getEmployees={getEmployees(apiGet)}
                                        fetchExchangeRate={getExchangeRate(apiGet)}
                                        updateDocumentPayment={updateDocumentPayment(apiPut)}
                                        getPaymentSearchResult={getPaymentSearchResult(apiGet)}
                                        createDocumentPayment={assignDocumentPayment(apiPost)}
                                        deleteDocumentPayment={removeDocumentPayment(apiDelete)}
                                        createDocumentOtherPayment={createDocumentOtherPayment(apiPost)}
                                        updateDocumentOtherPayment={updateDocumentOtherPayment(apiPut)}
                                    />
                                }
                            />
                            <Route
                                path="docs"
                                element={
                                    <DocumentView
                                        currentDate={new Date().getDay() > 25 ? new Date() : subMonths(new Date(), 1)}
                                        getDocuments={getDocuments(apiGet)}
                                        uploadDocumentsWithSpecifications={uploadDocumentsWithSpecifications(apiPost)}
                                    />
                                }
                            ></Route>
                        </Route>

                        <Route
                            path="welcome"
                            element={
                                <Welcome
                                    updateProfile={updateProfile(apiPost)}
                                    fetchWelcomeData={getWelcomeData(apiGet)}
                                    getUserProfileData={getUserProfileData(apiGet)}
                                />
                            }
                        />
                        <Route path="not-allowed" element={<NotAllowedRoute />} />
                        <Route path="*" element={<NotFoundRoute />} />
                    </Route>,
                ),
            ),
        [],
    );

    return (
        <UserContextProvider getOrCreateUser={getOrCreateUser(apiGet)(apiPost)}>
            <RouterProvider router={router} />
        </UserContextProvider>
    );
};

export default AuthenticatedApp;
