import { AsyncResult } from "components/common/infractructure";
import { SelectValues } from "../../../../components/common/ui-kit/components/GenericSelect/GenericSelect";
import { PromotionEmailNotification, SendPromotionEmailRequest } from "../../types";
import { toast } from "react-hot-toast";
import { POSITION_ID_INITIAL_STATE } from "./PromotionsList";
import { SendEmailModal } from "../../../../components/common/ui-kit-business/SendEmailModal/SendEmailModal";
import { EmailPreview, SendEmailRequest } from "../../../../components/common/types";

interface Props {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    availableEmails: SelectValues[];
    notifyAboutPromotion: (requestBody: SendPromotionEmailRequest) => AsyncResult<PromotionEmailNotification>;
    getEmailPreview: (positionAssignmentId: number, promotionMonth: string) => AsyncResult<EmailPreview>;
    positionAssignmentId: number;
    promotionMonth: string;
    onNotificationSent: (newNotification: PromotionEmailNotification, positionId: number) => void;
}

export const SendNotificationEmailModal = (props: Props) => {
    const handleClick = async (request: SendEmailRequest) => {
        (
            await props.notifyAboutPromotion({
                positionAssignmentId: props.positionAssignmentId,
                yearMonth: props.promotionMonth,
                cc: request.cc[0],
                from: request.from,
                to: request.to[0],
                subject: request.subject,
                body: request.body,
            })
        ).match({
            Ok: res => {
                props.onNotificationSent(res, props.positionAssignmentId);
                toast.success("Pomyślnie powiadomiono pracownika o awansie!");
            },
            Err: () => {
                toast.error("Nie udało się wysłać powiadomienia o awansie!");
            },
        });
    };

    const getPrefilledEmail = () => {
        return props.getEmailPreview(props.positionAssignmentId, props.promotionMonth);
    };

    return (
        <SendEmailModal
            showDialog={props.showDialog}
            setShowDialog={props.setShowDialog}
            availableEmails={props.availableEmails}
            shouldLoadPrefilledEmail={props.positionAssignmentId !== POSITION_ID_INITIAL_STATE}
            getEmailPreview={getPrefilledEmail}
            handleClick={handleClick}
        />
    );
};
