import { TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { validateEmail } from "../../utils";

interface Props {
    setIsValidHandler?: (value: boolean) => void;
    setEmailsHandler?: (value: string[]) => void;
    disabled?: boolean;
    emails: string[];
    label: string;
    sx: SxProps<Theme>;
    required: boolean;
}

export const EmailsInput = (props: Props) => {
    const [isValid, setIsValid] = useState(true);
    const [value, setValue] = useState<string>(props.emails.join(", ") || "");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.currentTarget.value;
        const emails = currentValue
            .split(",")
            .map(e => e.trim())
            .filter(e => e.length !== 0);
        const isValid = props.required
            ? emails.length !== 0 && emails.every(validateEmail)
            : emails.every(validateEmail);
        setIsValid(isValid);
        setValue(currentValue);
        props.setIsValidHandler && props.setIsValidHandler(isValid);
        props.setEmailsHandler && props.setEmailsHandler(emails);
    };

    return (
        <TextField
            error={!isValid}
            variant="standard"
            label={props.label}
            size="small"
            fullWidth
            sx={{ ...props.sx }}
            value={value}
            onChange={handleChange}
            disabled={!!props.disabled ? props.disabled : false}
        />
    );
};
