import {
    DateString,
    getSpecializationTranslation,
    NamedEnumeration,
    Nullable,
    packNamedEnum,
    Specialization,
    toDateString,
    YupObjectShape,
} from "components/common/types";
import { omit, pick } from "ramda";
import { Maybe } from "true-myth";
import * as yup from "yup";
import { isBefore } from "date-fns";
import { validateEmail } from "../../components/common/utils";

const ACCOUNT_REGEXP = /^([\d]{3}|[Xx]{3})-([\d]{4}|[Xx]{4})-([\d]{4}|[Xx]{4})$/;

interface WithId {
    id: number;
}

interface ClientDTO extends WithId {
    companyName: string;
}

type Client = ClientDTO;

const mapClientDTO = (clientDto: ClientDTO): Client => ({
    ...clientDto,
});

export const AllRateTypes = ["HOUR", "HALFDAY", "DAY", "FIXED_PRICE", "SERVICE"] as const;
export type RateType = typeof AllRateTypes[number];

export const AllProjectCurrencies = ["PLN", "EUR", "USD", "CHF", "AUD", "ZAR", "GBP", "SEK"] as const;
type ProjectCurrency = typeof AllProjectCurrencies[number];

export const AllLanguages = ["PL", "EN"] as const;
type Language = typeof AllLanguages[number];

interface AccountDTO extends WithId {
    accountNumber: string;
    currency: ProjectCurrency;
}

export type Account = AccountDTO;

const mapAccountDTO = (accountDto: AccountDTO): Account => ({
    ...accountDto,
});

export interface EmployeeDTO extends WithId {
    firstName: string;
    lastName: string;
    specialization: Nullable<Specialization>;
    isActive: boolean;
    avatarUrl: string;
    level: string;
    currentRate: number;
    startDate: string;
}

export interface Employee extends WithId {
    firstName: string;
    lastName: string;
    specialization: Maybe<Specialization>;
    isActive: boolean;
    avatarUrl: string;
    level: string;
}

export const mapEmployeeDTO = (employeeDto: EmployeeDTO): Employee => ({
    ...employeeDto,
    specialization: Maybe.fromNullable(employeeDto.specialization),
});

export const formatEmployeeLabel = ({ firstName, lastName, isActive }: Employee) =>
    `${firstName} ${lastName}${isActive ? "" : " [NIEAKTYWNY]"}`;

export const formatEmployeeLabelWithDetails = ({ firstName, lastName, level, isActive }: Employee) =>
    `${firstName} ${lastName} ${isActive ? (level ? " (" + level + ")" : "") : " [NIEAKTYWNY]"}`;

export const formatEmployeeLabelWithSpec = (employee: Employee) => {
    const name = `${employee.lastName} ${employee.firstName}`;
    const specialization = employee.specialization.map(s => getSpecializationTranslation(s));
    const level = Maybe.of(employee.level);
    const inactive = employee.isActive ? Maybe.nothing<string>() : Maybe.of("NIEKATYWNY");
    const joinedValues = [level, specialization, inactive]
        .map(just => just.unwrapOr(""))
        .filter(val => val.length > 0)
        .join(", ");

    return `${name} (${joinedValues})`;
};

const AllBillingTypes = ["PER_EMPLOYEE", "PER_POSITION", "PER_DESCRIPTION"] as const;
export type BillingType = typeof AllBillingTypes[number];

interface BillingDetailsDTO {
    account: AccountDTO;
    language: NamedEnumeration<Language>;
    currency: NamedEnumeration<ProjectCurrency>;
    signer: EmployeeDTO;
    notes: Nullable<string>;
    paymentDays: number;
    billingType: NamedEnumeration<BillingType>;
    slackChannel: Nullable<string>;
}

interface BillingEmailDetailsDTO {
    language: NamedEnumeration<Language>;
    recipient: string;
    to: string[];
    cc: string[];
}

interface BillingDetails {
    account: Account;
    language: Language;
    currency: ProjectCurrency;
    signer: Employee;
    notes: Maybe<string>;
    paymentDays: number;
    billingType: BillingType;
    slackChannel: Maybe<string>;
}

interface BillingEmailDetails {
    language: Language;
    recipient: string;
    to: string[];
    cc: string[];
}

const mapBillingDetailsDTO = (billingDetailsDto: BillingDetailsDTO): BillingDetails => ({
    ...billingDetailsDto,
    currency: billingDetailsDto.currency.name,
    language: billingDetailsDto.language.name,
    billingType: billingDetailsDto.billingType.name,
    account: mapAccountDTO(billingDetailsDto.account),
    signer: mapEmployeeDTO(billingDetailsDto.signer),
    notes: Maybe.of(billingDetailsDto.notes),
    slackChannel: Maybe.of(billingDetailsDto.slackChannel),
});

const mapBillingEmailDetailsDTO = (billingEmailDetailsDto: BillingEmailDetailsDTO): BillingEmailDetails => ({
    language: billingEmailDetailsDto.language?.name || "EN",
    recipient: billingEmailDetailsDto.recipient || "",
    to: billingEmailDetailsDto.to,
    cc: billingEmailDetailsDto.cc,
});

export interface ProjectDTO {
    id: number;
    start: string;
    end: Nullable<string>;
    name: string;
    rate: Nullable<number>;
    currency: Nullable<NamedEnumeration<ProjectCurrency>>;
    rateType: Nullable<NamedEnumeration<RateType>>;
    client: Nullable<ClientDTO>;
    provision: Nullable<number>;
    isIpBox: Nullable<boolean>;
    accountingNumber: Nullable<number>;
    isInternal: boolean;
    billingDetails: Nullable<BillingDetailsDTO>;
    currentTeam: EmployeeDTO[];
    accountant: Employee | null;
}

export interface Project {
    id: number;
    start: Date;
    end: Maybe<Date>;
    name: string;
    rate: Maybe<number>;
    currency: Maybe<ProjectCurrency>;
    rateType: Maybe<RateType>;
    client: Maybe<Client>;
    provision: Maybe<number>;
    isIpBox: boolean;
    accountingNumber: Maybe<number>;
    isInternal: boolean;
    billingDetails: Maybe<BillingDetails>;
    isAccountable: boolean;
    currentTeam: Employee[];
    accountant: Maybe<Employee>;
}

export const mapProjectDTO = (projectDto: ProjectDTO): Project => ({
    ...projectDto,
    start: new Date(projectDto.start),
    end: Maybe.of(projectDto.end).map(date => new Date(date)),
    rate: Maybe.of(projectDto.rate),
    currency: Maybe.of(projectDto.currency).map(({ name }) => name),
    rateType: Maybe.of(projectDto.rateType).map(({ name }) => name),
    client: Maybe.of(projectDto.client).map(mapClientDTO),
    provision: Maybe.of(projectDto.provision),
    isIpBox: Maybe.of(projectDto.isIpBox).unwrapOr(false),
    accountingNumber: Maybe.of(projectDto.accountingNumber),
    billingDetails: Maybe.of(projectDto.billingDetails).map(mapBillingDetailsDTO),
    isAccountable: projectDto.isInternal ? Maybe.of(projectDto.accountingNumber).isJust() : true,
    currentTeam: projectDto.currentTeam.map(mapEmployeeDTO),
    accountant: Maybe.of(projectDto.accountant),
});

export interface ProjectDetailsDTO {
    id: number;
    start: string;
    end: Nullable<string>;
    name: string;
    rate: Nullable<number>;
    currency: Nullable<NamedEnumeration<ProjectCurrency>>;
    rateType: Nullable<NamedEnumeration<RateType>>;
    client: Nullable<ClientDTO>;
    provision: Nullable<number>;
    isIpBox: Nullable<boolean>;
    accountingNumber: Nullable<number>;
    isInternal: boolean;
    billingDetails: Nullable<BillingDetailsDTO>;
    accountant: Nullable<Employee>;
    billingEmailDetails: Nullable<BillingEmailDetailsDTO>;
}

export interface ProjectDetails {
    id: number;
    start: Date;
    end: Maybe<Date>;
    name: string;
    rate: Maybe<number>;
    currency: Maybe<ProjectCurrency>;
    rateType: Maybe<RateType>;
    client: Maybe<Client>;
    provision: Maybe<number>;
    isIpBox: boolean;
    accountingNumber: Maybe<number>;
    isInternal: boolean;
    billingDetails: Maybe<BillingDetails>;
    isAccountable: boolean;
    accountant: Maybe<Employee>;
    billingEmailDetails: Maybe<BillingEmailDetails>;
}

// TODO: isInternal is a hack. API should be splited into external and internal project flow.
export const mapProjectDetailsDTO = (projectDto: ProjectDetailsDTO, isInternal?: boolean): ProjectDetails => ({
    ...projectDto,
    start: new Date(projectDto.start),
    end: Maybe.of(projectDto.end).map(date => new Date(date)),
    rate: Maybe.of(projectDto.rate),
    currency: Maybe.of(projectDto.currency).map(({ name }) => name),
    rateType: Maybe.of(projectDto.rateType).map(({ name }) => name),
    client: Maybe.of(projectDto.client).map(mapClientDTO),
    provision: Maybe.of(projectDto.provision),
    isIpBox: Maybe.of(projectDto.isIpBox).unwrapOr(false),
    accountingNumber: Maybe.of(projectDto.accountingNumber),
    billingDetails: Maybe.of(projectDto.billingDetails).map(mapBillingDetailsDTO),
    isAccountable: projectDto.isInternal ? Maybe.of(projectDto.accountingNumber).isJust() : true,
    isInternal: isInternal ?? projectDto.isInternal,
    accountant: Maybe.of(projectDto.accountant),
    billingEmailDetails: Maybe.of(projectDto.billingEmailDetails)
        .map(mapBillingEmailDetailsDTO)
        .orElse(() =>
            Maybe.of({
                language: "EN",
                recipient: "",
                to: [],
                cc: [],
            }),
        ),
});

interface ProjectRoleDTO {
    id: number;
    name: string;
}

export type ProjectRole = ProjectRoleDTO;

const mapProjectRoleDTO = (projectRoleDto: ProjectRoleDTO): ProjectRole => ({
    ...projectRoleDto,
});

export interface ProjectConfigDTO {
    rateTypes: NamedEnumeration<RateType>[];
    currencies: NamedEnumeration<ProjectCurrency>[];
    billingTypes: NamedEnumeration<BillingType>[];
    languages: NamedEnumeration<Language>[];
    clients: ClientDTO[];
    projectRoles: ProjectRoleDTO[];
    employees: EmployeeDTO[];
    accounts: AccountDTO[];
}

export interface ProjectConfig {
    rateTypes: RateType[];
    currencies: ProjectCurrency[];
    billingTypes: BillingType[];
    languages: Language[];
    clients: Client[];
    projectRoles: ProjectRole[];
    employees: Employee[];
    accounts: Account[];
}

export const mapProjectConfigDTO = (projectConfigDto: ProjectConfigDTO): ProjectConfig => ({
    ...projectConfigDto,
    rateTypes: projectConfigDto.rateTypes.map(({ name }) => name),
    currencies: projectConfigDto.currencies.map(({ name }) => name),
    billingTypes: projectConfigDto.billingTypes.map(({ name }) => name),
    languages: projectConfigDto.languages.map(({ name }) => name),
    clients: projectConfigDto.clients.map(mapClientDTO),
    projectRoles: projectConfigDto.projectRoles.map(mapProjectRoleDTO),
    employees: projectConfigDto.employees.map(mapEmployeeDTO),
    accounts: projectConfigDto.accounts.map(mapAccountDTO),
});

export interface BillingDetailsFormData {
    accountId: number;
    signerId: number;
    language: Language;
    currency: ProjectCurrency;
    paymentDays: number;
    billingType: BillingType;
    slackChannel: Nullable<string>;
    notes: Nullable<string>;
}

export interface BillingEmailDetailsFormData {
    language: Language;
    recipient: string;
    to: string[];
    cc: string[];
}

const fromBillingDetails = ({
    account,
    signer,
    language,
    currency,
    paymentDays,
    billingType,
    slackChannel,
    notes,
}: BillingDetails): BillingDetailsFormData => ({
    accountId: account.id,
    signerId: signer.id,
    language,
    currency,
    paymentDays,
    billingType,
    slackChannel: slackChannel.unwrapOr(null),
    notes: notes.unwrapOr(null),
});

const fromBillingEmailDetails = ({
    language,
    recipient,
    to,
    cc,
}: BillingEmailDetails): BillingEmailDetailsFormData => ({
    language: language,
    recipient: recipient,
    to: to,
    cc: cc,
});

const billingDetailsFormDataSchema = yup.object<YupObjectShape<BillingDetailsFormData>>({
    accountId: yup.number().required(),
    signerId: yup.number().required(),
    language: yup
        .string()
        .required()
        .oneOf([...AllLanguages]),
    currency: yup
        .string()
        .required()
        .oneOf([...AllProjectCurrencies]),
    paymentDays: yup.number().integer().min(0).required(),
    billingType: yup
        .string()
        .required()
        .oneOf([...AllBillingTypes]),
    slackChannel: yup.string(),
    notes: yup.string(),
});

const billingEmailDetailsFormDataSchema = yup.object<YupObjectShape<BillingEmailDetailsFormData>>({
    language: yup
        .string()
        .required()
        .oneOf([...AllLanguages]),
    recipient: yup.string().required(),
    to: yup
        .array()
        .of(yup.string().test("valid", "invalid email", validateEmail))
        .min(1, "You must provide at least one email recipient"),
    cc: yup.array().of(yup.string().test("valid", "invalid email", validateEmail)),
});

export type BillingDetailsFormDataDTO = Omit<BillingDetailsFormData, "currency" | "billingType" | "language"> & {
    currency: NamedEnumeration<ProjectCurrency>;
    billingType: NamedEnumeration<BillingType>;
    language: NamedEnumeration<Language>;
};

export const toBillingDetailsFormDataDTO = (
    billingDetailsFormData: BillingDetailsFormData,
): BillingDetailsFormDataDTO => ({
    ...billingDetailsFormData,
    currency: packNamedEnum(billingDetailsFormData.currency),
    billingType: packNamedEnum(billingDetailsFormData.billingType),
    language: packNamedEnum(billingDetailsFormData.language),
});

export type BillingEmailDetailsFormDataDTO = Omit<BillingEmailDetailsFormData, "language"> & {
    language: NamedEnumeration<Language>;
};

export const toBillingEmailDetailsFormDataDTO = (
    billingEmailDetailsFormData: BillingEmailDetailsFormData,
): BillingEmailDetailsFormDataDTO => ({
    ...billingEmailDetailsFormData,
    language: packNamedEnum(billingEmailDetailsFormData.language),
});

export interface ProjectFormData {
    isInternal: boolean;
    name: string;
    rate: Nullable<number>;
    currency: Nullable<ProjectCurrency>;
    rateType: Nullable<RateType>;
    provision: Nullable<number>;
    start: DateString;
    end: Nullable<DateString>;
    isIpBox: Nullable<boolean>;
    clientId: Nullable<number>;
    isAccountable: boolean;
    billingDetails: Nullable<BillingDetailsFormData>;
    accountant: Employee | null;
    billingEmailDetails: Nullable<BillingEmailDetailsFormData>;
}

export const fromProject = ({
    isInternal,
    name,
    rate,
    currency,
    rateType,
    provision,
    start,
    end,
    isIpBox,
    client,
    billingDetails,
    accountingNumber,
    accountant,
    billingEmailDetails,
}: ProjectDetails): ProjectFormData => ({
    isInternal,
    name,
    rate: rate.unwrapOr(null),
    currency: currency.unwrapOr(null),
    rateType: rateType.unwrapOr(null),
    provision: provision.unwrapOr(null),
    start: toDateString(start),
    end: end.map(toDateString).unwrapOr(null),
    isIpBox,
    clientId: client.map(({ id }) => id).unwrapOr(null),
    isAccountable: isInternal ? accountingNumber.isJust() : true,
    billingDetails: billingDetails.map(fromBillingDetails).unwrapOr(null),
    accountant: accountant.unwrapOr(null),
    billingEmailDetails: billingEmailDetails.map(fromBillingEmailDetails).unwrapOr(null),
});

export type ProjectFormDataDTO = Omit<
    ProjectFormData,
    "currency" | "rateType" | "billingDetails" | "accountant" | "billingEmailDetails"
> & {
    currency: Nullable<NamedEnumeration<ProjectCurrency>>;
    rateType: Nullable<NamedEnumeration<RateType>>;
    billingDetails: Nullable<BillingDetailsFormDataDTO>;
    accountantId: number | undefined;
    billingEmailDetails: Nullable<BillingEmailDetailsFormDataDTO>;
};

export type InternalProjectFormDataDTO = Pick<
    ProjectFormDataDTO,
    "isInternal" | "name" | "start" | "end" | "isAccountable"
>;

export type CommercialProjectFormDataDTO = Omit<ProjectFormDataDTO, "isAccountable">;

export const toProjectFormDataDTO = (projectFormData: ProjectFormData): ProjectFormDataDTO => ({
    ...projectFormData,
    currency: Maybe.of(projectFormData.currency).map(packNamedEnum).unwrapOr(null),
    rateType: Maybe.of(projectFormData.rateType).map(packNamedEnum).unwrapOr(null),
    billingDetails: Maybe.of(projectFormData.billingDetails).map(toBillingDetailsFormDataDTO).unwrapOr(null),
    accountantId: projectFormData.accountant?.id,
    billingEmailDetails: Maybe.of(projectFormData.billingEmailDetails)
        .map(toBillingEmailDetailsFormDataDTO)
        .unwrapOr(null),
});

export const pickInternalProjectFormDataDTO = (projectFormDataDTO: ProjectFormDataDTO): InternalProjectFormDataDTO =>
    pick<ProjectFormDataDTO, keyof InternalProjectFormDataDTO>(
        ["isInternal", "name", "start", "end", "isAccountable"],
        projectFormDataDTO,
    );

export const pickCommercialProjectFormDataDTO = (
    projectFormDataDTO: ProjectFormDataDTO,
): CommercialProjectFormDataDTO => omit<ProjectFormDataDTO, "isAccountable">(["isAccountable"], projectFormDataDTO);

export const projectFormDataSchema = yup.object<YupObjectShape<ProjectFormData>>({
    isInternal: yup.boolean().required().default(false),
    name: yup.string().required(),
    rate: yup
        .number()
        .nullable()
        .when("isInternal", {
            is: false,
            then: yup.number().required().min(0),
        }),
    currency: yup
        .string()
        .nullable()
        .when("isInternal", {
            is: false,
            then: yup
                .string()
                .required()
                .oneOf([...AllProjectCurrencies]),
        }),
    rateType: yup
        .string()
        .nullable()
        .when("isInternal", {
            is: false,
            then: yup
                .string()
                .required()
                .oneOf([...AllRateTypes]),
        }),
    provision: yup
        .number()
        .nullable()
        .when("isInternal", {
            is: false,
            then: yup.number().required().min(0),
        }),
    start: yup.string().required(),
    end: yup.string().nullable(),
    isIpBox: yup
        .boolean()
        .nullable()
        .when("isInternal", {
            is: false,
            then: yup.boolean().required().default(false),
        }),
    clientId: yup.number().nullable().when("isInternal", {
        is: false,
        then: yup.number().required(),
    }),
    isAccountable: yup
        .boolean()
        .nullable()
        .default(true)
        .when("isInternal", {
            is: true,
            then: yup.boolean().required().default(false),
        }),
    billingDetails: billingDetailsFormDataSchema.nullable().when("isInternal", {
        is: false,
        then: billingDetailsFormDataSchema.required(),
    }),
    accountant: yup.object(),
    billingEmailDetails: billingEmailDetailsFormDataSchema.nullable().when("isInternal", {
        is: false,
        then: billingEmailDetailsFormDataSchema.required(),
    }),
});

const getDefaultIdValue = (elements: WithId[]) =>
    Maybe.head(elements)
        .map(({ id }) => id)
        .unwrapOr(-1);

const getDefaultEmployeeIdValue = (elements: Employee[]) =>
    Maybe.find(
        ({ firstName, lastName, isActive }) => isActive && firstName === "Jan" && lastName === "Zborowski",
        elements,
    )
        .orElse(() => Maybe.head(elements.filter(({ isActive }) => isActive)))
        .map(({ id }) => id)
        .unwrapOr(-1);

export const getNewProjectFormDataDefaults = (
    now: Date,
    { clients, accounts, employees }: ProjectConfig,
): ProjectFormData => ({
    isInternal: false,
    name: "",
    rate: 600,
    currency: "USD",
    rateType: "DAY",
    provision: 0,
    start: toDateString(now),
    end: null,
    isIpBox: false,
    clientId: getDefaultIdValue(clients),
    isAccountable: false,
    billingDetails: {
        accountId: getDefaultIdValue(accounts),
        signerId: getDefaultEmployeeIdValue(employees),
        language: "PL",
        currency: "PLN",
        paymentDays: 21,
        billingType: "PER_EMPLOYEE",
        slackChannel: "",
        notes: "",
    },
    accountant: null,
    billingEmailDetails: {
        language: "EN",
        recipient: "",
        to: [],
        cc: [],
    },
});

export interface VacancyDTO {
    id: number;
    projectId: number;
    projectName: string;
    role: ProjectRoleDTO;
    description: Nullable<string>;
    start: string;
    end: Nullable<string>;
    rate: number;
    discount: Nullable<number>;
    provision: Nullable<number>;
    employee: EmployeeDTO;
    account: Nullable<string>;
    isEditable: boolean;
    fullTimeEquivalent: number;
    isCommercial: boolean;
}

export interface Vacancy {
    id: number;
    projectId: number;
    projectName: string;
    role: ProjectRole;
    description: Maybe<string>;
    start: Date;
    end: Maybe<Date>;
    rate: number;
    discount: Maybe<number>;
    provision: Maybe<number>;
    employee: Employee;
    account: Maybe<string>;
    isEditable: boolean;
    fullTimeEquivalent: number;
    isCommercial: boolean;
}

export const mapVacancyDTO = (vacancyDto: VacancyDTO): Vacancy => ({
    ...vacancyDto,
    role: mapProjectRoleDTO(vacancyDto.role),
    start: new Date(vacancyDto.start),
    end: Maybe.of(vacancyDto.end).map(date => new Date(date)),
    employee: mapEmployeeDTO(vacancyDto.employee),
    description: Maybe.of(vacancyDto.description),
    discount: Maybe.of(vacancyDto.discount),
    provision: Maybe.of(vacancyDto.provision),
    account: Maybe.of(vacancyDto.account),
    projectName: vacancyDto.projectName,
});

export interface VacancyFormData {
    roleId: number;
    employeeId: Nullable<number>;
    start: DateString;
    end: Nullable<DateString>;
    rate: number;
    discount: Nullable<number>;
    provision: Nullable<number>;
    description: Nullable<string>;
    account: Nullable<string>;
    fullTimeEquivalent: number;
}

export const filterByPendingCondition = (end: Maybe<Date>, now: Date, showPendingOnly: boolean) =>
    showPendingOnly ? end.map(endDate => isBefore(now, endDate)).unwrapOr(true) : true;

export const fromVacancy = ({
    role,
    employee,
    start,
    end,
    rate,
    discount,
    provision,
    description,
    account,
    fullTimeEquivalent,
}: Vacancy): VacancyFormData => ({
    roleId: role.id,
    employeeId: employee.id,
    start: toDateString(start),
    end: end.map(toDateString).unwrapOr(null),
    rate,
    discount: discount.unwrapOr(0),
    provision: provision.unwrapOr(0),
    description: description.unwrapOr(""),
    account: account.unwrapOr(""),
    fullTimeEquivalent,
});

export const vacancyFormDataSchema = yup.object<YupObjectShape<VacancyFormData>>({
    roleId: yup.number().min(0).required(),
    employeeId: yup.number().required(),
    start: yup.string().required(),
    end: yup.string().nullable(),
    rate: yup.number().min(0).required(),
    discount: yup.number().min(0).max(100).nullable(),
    provision: yup.number().min(0).max(100).nullable(),
    description: yup.string().nullable(),
    account: yup.string().required().matches(ACCOUNT_REGEXP),
    fullTimeEquivalent: yup.number().min(0).max(100).required(),
});

export const getNewVacancyFormDataDefaults = (now: Date, rate: number): VacancyFormData => ({
    roleId: 1,
    employeeId: null,
    start: toDateString(now),
    end: null,
    rate: rate,
    discount: 0,
    provision: 0,
    description: "",
    account: "",
    fullTimeEquivalent: 100,
});

export const rateTypeLabels: Record<RateType, string> = {
    DAY: "Za dzień",
    HALFDAY: "Za połowę dnia",
    FIXED_PRICE: "Fixed-price",
    HOUR: "Godzinowo",
    SERVICE: "Za usługę",
};

export const AllSpecializationAccounts = [
    "0100",
    "0200",
    "0201",
    "0202",
    "0203",
    "0204",
    "0205",
    "0206",
    "0300",
    "0400",
    "0500",
    "0600",
    "0700",
    "OTHER",
] as const;
export type SpecializationAccount = typeof AllSpecializationAccounts[number];

export const specializationAccountLabel: Record<SpecializationAccount, string> = {
    "0100": "Ogólne",
    "0200": "Dev",
    "0201": "Dev Backend Java",
    "0202": "Dev Backend Scala",
    "0203": "Dev DevOps",
    "0204": "Dev Frontend",
    "0205": "Dev QA",
    "0206": "Dev UX/UI",
    "0300": "Scalar",
    "0400": "Public Blockchain",
    "0500": "Private Blockchain",
    "0600": "Machine Learning",
    "0700": "DevOps as a Service",
    OTHER: "Pozostałe",
};

export const getSpecializationFromAccount = (a: string): SpecializationAccount =>
    a.match(ACCOUNT_REGEXP)?.length ? AllSpecializationAccounts.find(it => it === a.split("-")[2]) ?? "OTHER" : "OTHER";

export const specializationAccount: Record<Specialization, SpecializationAccount> = {
    ADMINISTRACJA: "0100",
    BIZDEV: "0100",
    MARKETING: "0100",
    HIRING: "0100",
    DEV_BACKEND_JAVA: "0201",
    DEV_BACKEND_SCALA: "0202",
    DEV_DEVOPS: "0203",
    DEV_FRONTEND: "0204",
    DEV_QA: "0205",
    UI_UX: "0206",
    PUBLIC_BLOCKCHAIN: "0400",
    PRIVATE_BLOCKCHAIN: "0500",
    MACHINE_LEARNING: "0600",
    DEVOPS_AS_A_SERVICE: "0700",
};
