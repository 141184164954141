import { ApiGet, ApiUpdate, AsyncResult } from "components/common/infractructure";
import {
    BillingData,
    BillingOverview,
    BillingSummary,
    CostBillingData,
    PendingBillingData,
} from "modules/Billing/types";
import { Maybe } from "true-myth";
import { Employee, EmployeeDTO } from "../../Projects/types";
import { EmailPreview, SendEmailRequest } from "../../../components/common/types";

const BILLING_API_PREFIX = "/billing";
const INVOICE_API_PREFIX = "/invoice";

export const getPendingBillings = (apiGet: ApiGet<PendingBillingData[]>) => (): AsyncResult<PendingBillingData[]> =>
    apiGet(`${BILLING_API_PREFIX}/pendingBillings`);
export const getMissingMonthlySettlements =
    (apiGet: ApiGet<EmployeeDTO[]>) =>
    (yearMonth: string): AsyncResult<Employee[]> =>
        apiGet(`/monthlySettlement/missing?month=${yearMonth}`).then(res =>
            res.map(employeesDto =>
                employeesDto.map(employeeDto => ({
                    ...employeeDto,
                    specialization: Maybe.of(employeeDto.specialization),
                })),
            ),
        );

export const sendMissingSettlementNotification =
    (apiPost: ApiUpdate<void, void>) =>
    (employeeId: number, yearMonth: string): AsyncResult<void> =>
        apiPost(`/monthlySettlement/notify?employeeId=${employeeId}&yearMonth=${yearMonth}`);

export const getBillingsOverview =
    (apiGet: ApiGet<BillingOverview[]>) =>
    (yearMonth: string): AsyncResult<BillingOverview[]> =>
        apiGet(`${BILLING_API_PREFIX}/?month=${yearMonth}`);

export const unassignEmployeeFromInvoicePosition =
    (apiDelete: ApiUpdate<void, void>) =>
    (invoicePositionId: number): AsyncResult<void> =>
        apiDelete(`${INVOICE_API_PREFIX}/position/${invoicePositionId}`);

export const assignEmployeeToInvoicePosition =
    (apiPut: ApiUpdate<{ positionId: number; employeeId: number }, void>) =>
    (invoicePositionId: number, employeeId: number): AsyncResult<void> =>
        apiPut(`${INVOICE_API_PREFIX}/position/${invoicePositionId}?employeeId=${employeeId}`);

export const getBillingSummary =
    (apiGet: ApiGet<BillingSummary>) =>
    (billingId: string): AsyncResult<BillingSummary> =>
        apiGet(`${BILLING_API_PREFIX}/billingSummary?id=${billingId}`);

export const saveCostBillings =
    (apiPut: ApiUpdate<{ costBillings: CostBillingData[] }, void>) =>
    (billingId: string, costBillings: CostBillingData[]): AsyncResult<void> =>
        apiPut(`${BILLING_API_PREFIX}/costBillings?billingId=${billingId}`, {
            costBillings,
        });

export const sendBilling =
    (apiPost: ApiUpdate<void, void>) =>
    (billingId: string): AsyncResult<void> =>
        apiPost(`${BILLING_API_PREFIX}/sendBillingToTaxxo?id=${billingId}`);

export const markBillingAsDone =
    (apiPut: ApiUpdate<void, void>) =>
    (billingId: string): AsyncResult<void> =>
        apiPut(`${BILLING_API_PREFIX}/markBillingAsDone?id=${billingId}`);

export const acceptBilling =
    (apiPost: ApiUpdate<void, void>) =>
    (billingId: string): AsyncResult<void> =>
        apiPost(`${BILLING_API_PREFIX}/accept?id=${billingId}`);

export const recalculateAssigmentBillings =
    (apiPost: ApiUpdate<void, BillingData>) =>
    (billingId: string): AsyncResult<BillingData> =>
        apiPost(`${BILLING_API_PREFIX}/recalculateAssigmentBillings?id=${billingId}`);

export const getProjectBillingEmailPreview =
    (apiGet: ApiGet<EmailPreview>) =>
    (billingId: string): AsyncResult<EmailPreview> =>
        apiGet(`${BILLING_API_PREFIX}/${billingId}/projectBilling/emailPreview`);

export const sendProjectBilling =
    (apiPost: ApiUpdate<SendEmailRequest, void>) =>
    (billingId: string, request: SendEmailRequest): AsyncResult<void> =>
        apiPost(`${BILLING_API_PREFIX}/${billingId}/projectBilling/send`, request);
