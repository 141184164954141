import { SingleFileUpload } from "../../ui-kit/components/SingleFileUpload";
import { EmailAttachment } from "../../types";

interface Props {
    onAttachmentUpload: (attachment: EmailAttachment) => void;
}

export const AttachmentUpload = (props: Props) => {
    const toBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve((reader.result as string).replace("data:", "").replace(/^.+,/, ""));
            reader.onerror = error => reject(error);
        });

    const onFileUpload = async (file: File) => {
        const content: string = await toBase64(file);
        props.onAttachmentUpload({
            content: content,
            name: file.name,
            contentType: file.type,
        });
    };

    return <SingleFileUpload promptText="Przeciągnij i upuść załącznik" onFileUploaded={onFileUpload} />;
};
