import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { GenericSelect, OnChangeFn } from "../../ui-kit/components/GenericSelect";
import * as React from "react";
import { useEffect, useState } from "react";
import { Maybe } from "true-myth";
import { SelectValues } from "../../ui-kit/components/GenericSelect/GenericSelect";
import { AsyncResult } from "../../infractructure";
import { EmailsInput } from "../EmailsInput/EmailsInput";
import { AttachmentUpload } from "./AttachmentUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import { EmailAttachment, EmailPreview, SendEmailRequest } from "../../types";

interface Props {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    availableEmails: SelectValues[];
    shouldLoadPrefilledEmail: boolean;
    getEmailPreview: () => AsyncResult<EmailPreview>;
    handleClick: (request: SendEmailRequest) => Promise<void>;
}

export const SendEmailModal = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [subject, setSubject] = useState<Maybe<string>>(Maybe.nothing());
    const [body, setBody] = useState<Maybe<string>>(Maybe.nothing());
    const [attachments, setAttachments] = useState<EmailAttachment[]>([]);
    const [isValidCC, setIsValidCC] = useState(true);
    const [isValidTo, setIsValidTo] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    const [ccValue, setCCValue] = useState<string[]>([]);
    const [toValue, setToValue] = useState<string[]>([]);
    const [currentFrom, setCurrentFrom] = useState<Maybe<SelectValues>>(
        Maybe.of(props.availableEmails.length > 0 ? props.availableEmails[0] : null),
    );
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (props.shouldLoadPrefilledEmail) {
            setLoading(true);
            setHasError(false);
            const abortController = new AbortController();
            const fetchData = async () => {
                try {
                    const res = await props.getEmailPreview();
                    res.match({
                        Ok: resolvedData => {
                            setBody(Maybe.of(resolvedData.prefilledEmail.bodyContent));
                            setSubject(Maybe.of(resolvedData.prefilledEmail.subjectContent));
                            setCCValue(resolvedData.prefilledEmail.cc);
                            setToValue(resolvedData.prefilledEmail.to);
                            setAttachments(resolvedData.attachments);
                            setLoading(false);
                            setHasError(false);
                        },
                        Err: () => {
                            setLoading(false);
                            setHasError(true);
                        },
                    });
                } catch (err) {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                    }
                }
            };

            fetchData();

            return () => {
                abortController.abort();
            };
        }
    }, [props.shouldLoadPrefilledEmail]);

    const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setSelectedTab(newValue);
    };

    const handleIsValidCc = (isValid: boolean) => {
        setIsValidCC(isValid);
    };

    const handleCcChange = (value: string[]) => {
        setCCValue(value);
    };

    const handleIsValidTo = (isValid: boolean) => {
        setIsValidTo(isValid);
    };

    const handleToChange = (value: string[]) => {
        setToValue(value);
    };

    const onChangeFrom: OnChangeFn = ({ target }, _child) => {
        const value = target.value as string;
        setCurrentFrom(Maybe.of({ display: value, value: value }));
    };

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const subject = e.currentTarget.value;
        setSubject(Maybe.of(subject));
    };

    const handleBodyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const body = e.currentTarget.value;
        setBody(Maybe.of(body));
    };

    const handleClick = async () => {
        props.setShowDialog(false);
        await props.handleClick({
            to: toValue,
            cc: ccValue,
            from: currentFrom.unsafelyUnwrap().value as string,
            subject: subject.unsafelyUnwrap(),
            body: body.unsafelyUnwrap(),
            attachments: attachments,
        });
    };

    const onAttachmentUpload = (attachment: EmailAttachment) => {
        setAttachments(attachments.slice().concat([attachment]));
    };

    const onAttachmentRemove = () => {
        setAttachments(attachments.slice(0, selectedTab - 1).concat(attachments.slice(selectedTab)));
        setSelectedTab(0);
    };

    const attachmentComponents = attachments.map((attachment, index) => (
        <div
            key={index + 1}
            style={{ padding: "20px 0 13px 0", overflowY: "hidden" }}
            hidden={selectedTab != index + 1}
        >
            <embed
                style={{ paddingTop: "5px" }}
                width={928}
                height={588}
                src={`data:${attachment.contentType};base64,${attachment.content}`}
            />
            <Tooltip title="Usuń załącznik">
                <IconButton
                    type="button"
                    onClick={onAttachmentRemove}
                    sx={{
                        transform: "scale(0.8) translate(24px, -24px)",
                        boxShadow: 1,
                        transition: "transform 0.2s",
                        position: "absolute",
                        right: "0",
                        bottom: "120",
                        marginRight: "20px",

                        "&:hover": {
                            backgroundColor: "secondary.main",
                            transform: "scale(0.9) translate(22px, -22px)",
                            boxShadow: 3,
                        },

                        deleteButton: {
                            "&, &:hover": {
                                backgroundColor: "secondary.main",
                            },
                        },
                        iconButtonLabel: {
                            color: "background.paper",
                        },
                    }}
                >
                    <RemoveIcon />
                </IconButton>
            </Tooltip>
        </div>
    ));

    const attachmentTabs = attachments.map((attachment, index) => (
        <Tab key={index} label={`Załącznik ${attachment.name}`} />
    ));

    return (
        <Dialog open={props.showDialog} maxWidth={"md"} fullWidth={true} onClose={() => props.setShowDialog(false)}>
            <Typography variant="h4" sx={{ textAlign: "center", fontSize: 24 }}>
                Wyślij email
            </Typography>

            {!hasError && !loading && body.isJust() && subject.isJust() && currentFrom.isJust() ? (
                <>
                    <Tabs value={selectedTab} onChange={handleChangeActiveTab}>
                        <Tab label="Treść emaila" />
                        {attachmentTabs}
                        <Tab label="Dodaj załącznik" />
                    </Tabs>

                    <div style={{ height: "600px", alignContent: "center" }}>
                        <div hidden={selectedTab != attachments.length + 1}>
                            <AttachmentUpload onAttachmentUpload={onAttachmentUpload} />
                        </div>
                        {attachmentComponents}
                        <div hidden={selectedTab != 0} style={{ overflowY: "hidden" }}>
                            <DialogContent>
                                <Grid container direction="column">
                                    <Grid item>
                                        <GenericSelect
                                            label="Od"
                                            value={currentFrom.unsafelyUnwrap().value}
                                            values={props.availableEmails}
                                            onChange={onChangeFrom}
                                            sx={{ width: "100%", marginTop: "30px" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <EmailsInput
                                            setIsValidHandler={handleIsValidTo}
                                            setEmailsHandler={handleToChange}
                                            emails={toValue}
                                            label="Do"
                                            sx={{ width: "100%", marginTop: "30px" }}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <EmailsInput
                                            setIsValidHandler={handleIsValidCc}
                                            setEmailsHandler={handleCcChange}
                                            emails={ccValue}
                                            label="CC"
                                            sx={{ width: "100%", marginTop: "30px" }}
                                            required={false}
                                        />
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <TextField
                                            variant="standard"
                                            label="Temat"
                                            size="small"
                                            fullWidth
                                            sx={{ width: "100%", marginTop: "30px" }}
                                            value={subject.unsafelyUnwrap()}
                                            onChange={handleSubjectChange}
                                        />
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <TextField
                                            variant="standard"
                                            label="Treść"
                                            multiline
                                            fullWidth
                                            rows={10}
                                            value={body.unsafelyUnwrap()}
                                            sx={{ width: "100%", marginTop: "30px" }}
                                            onChange={handleBodyChange}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </div>
                    </div>
                    <Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Button
                            disabled={!isValidCC || !isValidTo}
                            sx={{ marginTop: "25px" }}
                            onClick={() => handleClick()}
                            variant="contained"
                            color="primary"
                        >
                            Wyślij e-mail
                        </Button>
                    </Grid>
                </>
            ) : (
                <Grid container direction="column" alignItems={"center"}>
                    <Grid item>
                        <>
                            {hasError ? (
                                <p style={{ color: "red" }}>Nie udało się przygotwać treści maila!</p>
                            ) : (
                                <CircularProgress sx={{ maxHeight: "100px", marginTop: "50px" }} />
                            )}
                        </>
                    </Grid>
                </Grid>
            )}
        </Dialog>
    );
};
