import * as React from "react";
import { Maybe } from "true-myth";
import { Alert, Typography } from "@mui/material";
import { BillingData } from "modules/Billing/types";

type Props = Pick<BillingData, "accepted" | "acceptedBy" | "done" | "doneBy" | "sent" | "sentBy">;

export const BillingStateInfo: React.FC<Props> = ({ accepted, acceptedBy, done, doneBy, sent, sentBy }) => {
    if (accepted && !done) {
        return (
            <Alert severity="info">
                <Typography>
                    Faktura zaakceptowana przez <b>{acceptedBy}</b>, wysłaniem zajmie się <b>#adm</b>
                </Typography>
            </Alert>
        );
    } else if (done && !sent) {
        return (
            <Alert severity="info">
                {Maybe.of(acceptedBy)
                    .map(user => (
                        <Typography key={0}>
                            Zaakceptowano przez <b>{user}</b>
                        </Typography>
                    ))
                    .unwrapOr(<></>)}
                <Typography>
                    Wysłano/zamknięto przez <b>{doneBy}</b>
                </Typography>
            </Alert>
        );
    } else if (sent) {
        return (
            <Alert severity="info">
                {Maybe.of(acceptedBy)
                    .map(user => (
                        <Typography key={0}>
                            Zaakceptowano przez <b>{user}</b>
                        </Typography>
                    ))
                    .unwrapOr(<></>)}
                <Typography>
                    Wysłano/zamknięto przez <b>{doneBy}</b>
                </Typography>
                <Typography>
                    Faktura wysłana klientowi przez <b>{sentBy}</b>
                </Typography>
            </Alert>
        );
    }

    return <></>;
};
