import { Department, getSpecializationTranslation, Specialization } from "../../components/common/types";
import { EmployeeDTO, VacancyDTO } from "../Projects/types";
import { ContractorDTO, UserDTO } from "../UserProfile/types";

export interface PositionAssignment {
    id: number;
    employee: string;
    employeeDto: EmployeeDTO;
    employeeEmail: string;
    startDate: string;
    endDate: string | null;
    department: Department;
    specialization: Specialization | null;
    level: string | null;
}

export interface EmployeePromotion {
    employee: EmployeeDTO;
    previousRate: number;
    currentRate: number;
    previousLevel: string | null;
    currentLevel: string | null;
    type: "age" | "badge";
    positionId: number;
    notifications: PromotionEmailNotification[];
}

export interface PromotionEmailNotification {
    timestamp: string;
    senderEmail: string;
    recipientEmail: string;
    subject: string;
    sender: UserDTO;
}

export interface SendPromotionEmailRequest {
    positionAssignmentId: number;
    yearMonth: string;
    cc: string;
    from: string;
    to: string;
    subject: string;
    body: string;
}

export interface ExtendedPositionAssignment extends PositionAssignment {
    overallStartDate: string;
    overallEndDate: string | null;
    status: "Joined" | "Fired" | "Continuing";
}

export interface EmployeeDetails {
    positions: PositionAssignment[];
    employee: EmployeeDTO;
    vacancies: VacancyDTO[];
    contractorData: ContractorDTO | null;
}

export const formatEmployeeSpecAndRate = (employee: EmployeeDTO): string => {
    const specialization = employee.specialization;
    const currentRate = employee.currentRate;
    const level = employee.level;
    const translatedSpecialization =
        specialization != null ? getSpecializationTranslation(specialization) : "Nieznana Specjalizacja";

    if (specialization !== null && currentRate !== null) {
        return `${translatedSpecialization} ${level}, stawka: ${currentRate} zł`;
    } else if (specialization !== null) {
        return `${translatedSpecialization} ${level}`;
    } else if (currentRate !== null) {
        return `Stawka: ${currentRate} zł`;
    } else {
        return `${translatedSpecialization} ${level}`;
    }
};
