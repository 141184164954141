import * as React from "react";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { AsyncResult } from "../../../../components/common/infractructure";
import { EmployeePromotion, PromotionEmailNotification, SendPromotionEmailRequest } from "../../types";
import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";
import { EmployeeAvatar } from "../../../Projects/views/components/EmployeeAvatar";
import { mapEmployeeDTO } from "../../../Projects/types";
import MailIcon from "@mui/icons-material/Mail";
import { format, parseISO } from "date-fns";
import { useUserContext } from "../../../../components/common/contexts";
import { SelectValues } from "../../../../components/common/ui-kit/components/GenericSelect/GenericSelect";
import { isAllowed, ROLES_PROMOTION_NOTIFICATION } from "../../../../components/RBAC/RBACutils";
import { SendNotificationEmailModal } from "./SendNotificationEmailModal";
import { EmailPreview } from "../../../../components/common/types";

export const POSITION_ID_INITIAL_STATE = -1;

interface Props {
    yearMonth: string;
    notifyAboutPromotion: (requestBody: SendPromotionEmailRequest) => AsyncResult<PromotionEmailNotification>;
    getPromotions: (promotionMonth: string) => AsyncResult<EmployeePromotion[]>;
    getEmailPreview: (positionAssignmentId: number, promotionMonth: string) => AsyncResult<EmailPreview>;
}

export const PromotionsList: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [promotions, setPromotions] = useState<EmployeePromotion[]>([]);
    const { userProfileData } = useUserContext();
    const [openPromoteDialog, setOpenPromoteDialog] = useState<boolean>(false);
    const [currentPositionId, setCurrentPositionId] = useState<number>(POSITION_ID_INITIAL_STATE);

    const availableEmails: SelectValues[] = userProfileData.user.gmailTokens
        .map(t => t.email)
        .map(e => ({
            display: e,
            value: e,
        }));

    const setPromoteDialog = (state: boolean) => {
        setOpenPromoteDialog(state);
    };

    const addNotification = (newNotification: PromotionEmailNotification, positionId: number) => {
        setPromotions(currentPromotions =>
            currentPromotions.map(promotion =>
                promotion.positionId === positionId
                    ? {
                          ...promotion,
                          notifications: [...promotion.notifications, newNotification],
                      }
                    : promotion,
            ),
        );
    };

    const handlePromoteContractor = (positionAssignmentId: number) => {
        setCurrentPositionId(positionAssignmentId);
        setOpenPromoteDialog(true);
    };

    const hasNotificationRights = isAllowed(userProfileData.user.roles, ROLES_PROMOTION_NOTIFICATION);

    const disabledButtonMsg = hasNotificationRights
        ? "Skonfiguruj maila w ustawieniach profilu"
        : "Brak uprawnień do wysyłania powiadomień";

    const columns: GridColDef[] = [
        {
            field: "employee",
            headerName: "Osoba",
            width: 300,
            renderCell: p => {
                if (!p.row.employee) {
                    return <span>No employee data</span>;
                }
                return (
                    <>
                        <EmployeeAvatar
                            sx={{ width: "35px", height: "35px", marginRight: "15px" }}
                            employee={mapEmployeeDTO(p.row.employee)}
                        />
                        {p.row.employee.firstName + " " + p.row.employee.lastName}
                    </>
                );
            },
            flex: 1,
        },
        {
            field: "type",
            headerName: "Typ podwyżki",
            renderCell: params => (params.value === "age" ? "Stażowa" : "Awans"),
            flex: 1,
        },
        {
            field: "previousLevel",
            headerName: "Poziom",
            renderCell: params =>
                params.row.previousLevel != params.row.currentLevel
                    ? params.row.previousLevel + " → " + params.row.currentLevel
                    : params.row.previousLevel,
            flex: 1,
        },
        {
            field: "previousRate",
            headerName: "Poprzednia stawa",
            flex: 1,
        },
        { field: "currentRate", headerName: "Aktualna stawka", flex: 1 },
        {
            field: "positionId",
            headerName: "Powiadomienie",
            flex: 1,
            renderCell: params => {
                return params.row.notifications.length == 0 ? (
                    <Tooltip title={availableEmails.length == 0 || !hasNotificationRights ? disabledButtonMsg : ""}>
                        <div id={"promote-button-" + params.row.positionId}>
                            <IconButton
                                disabled={availableEmails.length == 0 || !hasNotificationRights}
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={() => handlePromoteContractor(params.row.positionId)}
                            >
                                <MailIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={
                            <>
                                <div>
                                    Od: {params.row.notifications[params.row.notifications.length - 1].senderEmail}
                                </div>
                                <div>
                                    Do: {params.row.notifications[params.row.notifications.length - 1].recipientEmail}
                                </div>
                                <div>
                                    Wysyłający:{" "}
                                    {params.row.notifications[params.row.notifications.length - 1].sender.firstName +
                                        " " +
                                        params.row.notifications[params.row.notifications.length - 1].sender.lastName}
                                </div>
                            </>
                        }
                    >
                        <p>
                            {" "}
                            {format(
                                parseISO(params.row.notifications[params.row.notifications.length - 1].timestamp),
                                "yyyy-MM-dd HH:mm",
                            )}
                        </p>
                    </Tooltip>
                );
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                const res = await props.getPromotions(props.yearMonth);
                res.match({
                    Ok: resolvedData => {
                        setPromotions(resolvedData);
                        setLoading(false);
                    },
                    Err: () => {
                        setPromotions([]);
                        setLoading(false);
                    },
                });
            } catch (err) {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            abortController.abort();
        };
    }, [props.yearMonth]);

    return (
        <>
            <SendNotificationEmailModal
                showDialog={openPromoteDialog}
                setShowDialog={setPromoteDialog}
                availableEmails={availableEmails}
                notifyAboutPromotion={props.notifyAboutPromotion}
                getEmailPreview={props.getEmailPreview}
                positionAssignmentId={currentPositionId}
                promotionMonth={props.yearMonth}
                onNotificationSent={addNotification}
            />

            <Grid container>
                {loading ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "200px" }}>
                            <CircularProgress sx={{ maxHeight: "100px", marginTop: "50px" }} />
                        </Box>
                    </Grid>
                ) : (
                    <DataGrid
                        localeText={{ noRowsLabel: "Brak podwyżek w wybranym miesiącu" }}
                        disableColumnMenu={true}
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableSelectionOnClick={true}
                        hideFooter={true}
                        hideFooterPagination={true}
                        hideFooterSelectedRowCount={true}
                        autoHeight={true}
                        sx={{
                            minHeight: 200,
                            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
                                outline: "transparent",
                            },
                            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within": {
                                outline: "none",
                            },
                            border: 0,
                        }}
                        rows={promotions.map(p => ({ ...p, id: p.employee.id }))}
                        columns={columns}
                    />
                )}
            </Grid>
        </>
    );
};
