import { Button } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/system";
import { SendEmailModal } from "../../../../components/common/ui-kit-business/SendEmailModal/SendEmailModal";
import { SelectValues } from "../../../../components/common/ui-kit/components/GenericSelect/GenericSelect";
import { useUserContext } from "../../../../components/common/contexts";
import { AsyncResult } from "../../../../components/common/infractructure";
import { EmailPreview, SendEmailRequest } from "../../../../components/common/types";

interface Props {
    onSendBilling: () => Promise<void>;
    onAcceptBilling: () => Promise<void>;
    accepted: boolean;
    isAccountant: boolean;
    done: boolean;
    getEmailPreview: () => AsyncResult<EmailPreview>;
    onSendEmail: (request: SendEmailRequest) => Promise<void>;
}

export const BillingActionButton: React.FC<Props> = (props: Props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const enabled = props.isAccountant || !props.done;
    const buttonText = () => {
        if (props.accepted && props.done) {
            return "Wyślij klientowi";
        }
        if (props.accepted) {
            return "Wyślij do Taxxo";
        }
        return "Akceptuj";
    };
    const onClick = async () => {
        try {
            if (props.accepted && props.done) {
                setShowDialog(true);
                return;
            }
            if (props.accepted) {
                await props.onSendBilling();
            } else {
                await props.onAcceptBilling();
            }
        } catch (error) {
            console.error("Error during billing action:", error);
        }
    };
    const { userProfileData } = useUserContext();
    const availableEmails: SelectValues[] = userProfileData.user.gmailTokens
        .map(t => t.email)
        .map(e => ({
            display: e,
            value: e,
        }));
    const handleClick = async (request: SendEmailRequest) => {
        await props.onSendEmail(request);
    };

    return (
        <>
            <SendEmailModal
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                availableEmails={availableEmails}
                shouldLoadPrefilledEmail={showDialog}
                getEmailPreview={props.getEmailPreview}
                handleClick={handleClick}
            />

            <Box mt={3} mb={3} width={1}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    title="Akceptuj"
                    onClick={onClick}
                    disabled={!enabled}
                    sx={{ display: "block", m: "auto", width: "8rem" }}
                >
                    {buttonText()}
                </Button>
            </Box>
        </>
    );
};
